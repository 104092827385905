:root {
    --cc-bg: #fff;
    --cc-text: #231D61;
    --cc-btn-primary-bg: #1D90F2;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #00a4ff;
    --cc-btn-secondary-bg: rgba(142, 145, 175, 0.3);
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: rgba(142, 145, 175, 0.8);
    --cc-toggle-bg-off: #8E91AF;
    --cc-toggle-bg-on: linear-gradient(115deg,#0087FA -30%,#7EFFF0 240%);
    --cc-toggle-bg-readonly: #d5dee2;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-bg-hover: #e9eff4;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e9edf2;
    --cc-overlay-bg: rgba(4, 6, 8, .85);
    --cc-webkit-scrollbar-bg: #cfd5db;
    --cc-webkit-scrollbar-bg-hover: #9199a0
}

.c_darkmode {
    --cc-bg: #181b1d;
    --cc-text: #d8e5ea;
    --cc-btn-primary-bg: #a6c4dd;
    --cc-btn-primary-text: #000;
    --cc-btn-primary-hover-bg: #c2dff7;
    --cc-btn-secondary-bg: #33383c;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #3e454a;
    --cc-toggle-bg-off: #667481;
    --cc-toggle-bg-on: linear-gradient(115deg,#0087FA -30%,#7EFFF0 240%);
    --cc-toggle-bg-readonly: #454c54;
    --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
    --cc-toggle-knob-icon-color: var(--cc-bg);
    --cc-block-text: #b3bfc5;
    --cc-cookie-category-block-bg: #23272a;
    --cc-cookie-category-block-bg-hover: #2b3035;
    --cc-section-border: #292d31;
    --cc-cookie-table-border: #2b3035;
    --cc-webkit-scrollbar-bg: #667481;
    --cc-webkit-scrollbar-bg-hover: #9199a0
}

/* CUSTOM STYLE OVERRIDE */
#cc-main {
    color: #231D61;
}
#cc-main .pm__section-desc, #cc-main .cm__desc {
    color: #8E91AF;
    font-size: 1.4rem;
    line-height: 2.5rem;
}
#cc-main .toggle__icon {
    display: flex;
    align-items: center;
}

#cc-main .section__toggle, #cc-main .section__toggle-wrapper, #cc-main .toggle__icon, #cc-main .toggle__label {
    height: 2.4rem;
    width: 4rem;
}

#cc-main .pm__section--toggle .pm__section-title, #cc-main .pm__section--toggle .pm__section-title:hover{
    background: white;
    cursor: unset;
}

#cc-main .section__toggle~.toggle__icon .toggle__icon-circle {
    transition: transform 0.2s ease-in-out;
}

#cc-main .section__toggle:checked~.toggle__icon .toggle__icon-circle {
    transform: translate(1.6rem, 0%);
}

#cc-main .section__toggle:checked~.toggle__icon{
    background: var(--cc-toggle-bg-on);
}

#cc-main .toggle__icon-circle{
    height: 1.6rem;
    width: 1.6rem;
    top: auto;
    left: 0.4rem;
}

#cc-main .section__toggle~.toggle__icon svg{
    display: none;
}

#cc-main .pm__close-btn {
    background: none;
    border: none;
}

#cc-main .pm__close-btn:hover {
    background: none;
    border: none;
    stroke: red;
    color: red;
}

#cc-main .pm__close-btn svg {
    stroke: #8E91AF;
}

#cc-main .pm__close-btn svg:hover {
    stroke: #acaec4;
}

#cc-main .pm__title, #cc-main cm__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #231D61;
    font-weight: 700;
}

#cc-main .pm__btn, #cc-main .cm__btn {
    border-radius: 20rem;
    padding: 1.8rem 2.1rem;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(to left,rgba(0,0,0,0.1),rgba(0,0,0,0));
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1.4rem;
    font-weight: 700;
    border: 0;
}

#cc-main .pm__btn:not(.pm__btn--secondary) {
    box-shadow: 0px 4px 8px rgba(0,110,204,0.2),inset 0px 1px 1px rgba(255,255,255,0.25);
}

#cc-main .pm__btn--secondary, #cc-main .cm__btn--secondary {
    color: #231D61;
}

#cc-main .pm__btn--secondary:hover, #cc-main .cm__btn--secondary:hover {
    background:#8E91AF;
    color: white;
}

#cc-main svg {
    width: unset;
}

#cc-main .cm__title, #cc-main .cm__desc {
    padding: 0!important;
}

#cc-main .cm__btns, #cc-main .cm__btn-group, #cc-main .pm__btn-group, #cc-main .pm__footer {
    gap: 0.5rem;
}

#cc-main .pm__footer {
    padding: 3.2rem;
}

#cc-main .pm__header, #cc-main .pm__body, #cc-main .cm__texts, #cc-main .cm__btns {
    padding: 3.2rem!important;

    @media (max-width: 640px) {
        padding: 3.2rem 1.5rem!important;
    }
}

@media (max-width: 640px) {
    #cc-main .pm__header {
        padding: 1rem 1.5rem!important;
    }
}
